.Cards{
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 cards per row on large screens */
    gap: 20px;
    margin-top:30px;
    margin: 0px 30px;
}

.title{
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .Cards{
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
}

