section {
    width: 100%;
    display: inline-block;
    background: #333;
    height: 30vh;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    text-decoration: underline;
  }

  @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,300,700);

* {
  font-family: Open Sans;
}

  .Logo{
    height: 50px;
    width: 100px;
    margin: 0px 20px;
  }

  .SubLogo{
     height: 50px;
    width: 100px;
    margin: 0px 10px;
  }
  
  .footer-distributed{
    border-top-left-radius: 25px; 
      border-top-right-radius: 25px; 
      border-top: 2px solid black;
      background: #a3a2a1;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      width: 100%;
      text-align: left;
      font: bold 16px sans-serif;
      padding: 35px 50px;
  }
  
  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right{
      display: inline-block;
      vertical-align: top;
  }
  
  /* Footer left */
  
  .footer-distributed .footer-left{
      width: 40%;
  }
  
  /* The company logo */
  
  .footer-distributed h3{
      color:  #000000;
      font: normal 36px 'Open Sans', cursive;
      margin: 0;
  }
  
  .footer-distributed h3 span{
      color:  rgb(0, 0, 0);
  }
  
  /* Footer links */
  
  .footer-distributed .footer-links{
      color:  #000000;
      margin: 20px 0 12px;
      padding: 0;
  }
  
  .footer-distributed .footer-links a{
      display:inline-block;
      line-height: 1.8;
    font-weight:400;
      text-decoration: none;
      color:  inherit;
  }
  
  .footer-distributed .footer-company-name{
      color:  #222;
      font-size: 14px;
      font-weight: normal;
      margin: 0;
  }
  
  /* Footer Center */
  
  .footer-distributed .footer-center{
      width: 35%;
  }
  
  .footer-distributed .footer-center i{
      background-color:  #33383b;
      color: #000000;
      font-size: 25px;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      text-align: center;
      line-height: 42px;
      margin: 10px 15px;
      vertical-align: middle;
  }
  
  .footer-distributed .footer-center i.fa-envelope{
      font-size: 17px;
      line-height: 38px;
  }
  
  .footer-distributed .footer-center p{
      display: inline-block;
      color: #000000;
    font-weight:400;
      vertical-align: middle;
      margin:0;
      margin-left: 10px;
      margin-top: 10px;
  }
  
  .footer-distributed .footer-center p span{
      display:block;
      font-weight: normal;
      font-size:14px;
      line-height:2;
  }
  
  .footer-distributed .footer-center p a{
      color:  rgb(16, 68, 240);
      text-decoration: none;;
  }
  
  .footer-distributed .footer-links a:before {
    content: "|";
    font-weight:300;
    font-size: 20px;
    left: 1;
    color: #000000;
    display: inline-block;
    padding-right: 5px;
    padding-left: 7px;
  }
  
  .footer-distributed .footer-links .link-1:before {
    content: none;
  }
  
  /* Footer Right */
  
  .footer-distributed .footer-right{
      width: 20%;
  }
  
  .footer-distributed .footer-company-about{
      line-height: 20px;
      color:  #000000;
      font-size: 13px;
      font-weight: normal;
      margin: 0;
  }
  
  .footer-distributed .footer-company-about span{
      display: block;
      color:  #000000;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 20px;
  }
  
  .footer-distributed .footer-icons{
      margin-top: 25px;
  }
  
  .footer-distributed .footer-icons a{
      display: inline-block;
      width: 35px;
      height: 30px;
      cursor: pointer;
      margin : 0px 10px;
      border-radius: 2px;
      font-size: 20px;
      color: #000000b8;
      text-align: center;
      line-height: 35px;
      margin-right: 3px;
      margin-bottom: 5px;
  }

  .footer-icons :hover{
    color: white;
    animation: 1s ease;
    filter: drop-shadow(white 5px 5px 15px);
  }
  
  /* If you don't want the footer to be responsive, remove these media queries */
  
  @media (max-width: 880px) {
  
      .footer-distributed{
          font: bold 14px sans-serif;
      }
  
      .footer-distributed .footer-left,
      .footer-distributed .footer-center,
      .footer-distributed .footer-right{
          display: block;
          width: 100%;
          margin-bottom: 40px;
          text-align: center;
      }
  
      .footer-distributed .footer-center i{
          margin-left: 0;
      }
  
  }
  