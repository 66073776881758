.ContactForm{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgba(152, 152, 152, 0.574);
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 30px;
    border-radius: 20px;
    border: 0.5px solid rgba(24, 24, 24, 0.278);
    box-shadow: 10px 20px 155px rgb(39, 39, 39);
}

.btn:hover{
    border: 1px solid black;
    background-color: white;
    box-shadow: 5px 5px 5px wheat;

}

.Inputs{
    display: flex;
    flex-direction: column;
}

.Inputs input{
    width: 500px;
    margin: 10px 20px;
    border-radius: 10px;
    height: 49px;
    padding-left: 10px;
}

.Inputs textarea{
    width: 500px;
    margin: 10px 20px;
    border-radius: 10px;
    height: 100px; /* Increased height for textarea */
    padding-left: 10px;
}

.btn{
    color: black;
    background-color: rgb(81, 255, 0);
    width: 300px;
    margin-left: 110px;
    margin-top: 20px;
}

/* Media query for mobile devices (assuming a maximum width of 768px for mobile) */
@media only screen and (max-width: 768px) {
    .ContactForm {
        padding: 10px; /* Reduces padding for very small screens */
        width: 90%;
        
        margin-bottom: 90px;
        box-shadow: 0px 0px 0px rgb(43, 42, 42) ;
    }
    .Inputs input, .Inputs textarea {
        width: 320px; /* Adjusts the width to 90% of the parent container for better fit on mobile screens */
        margin: 10px 0; /* Adjusts margins for better spacing on mobile */
    }
    .btn {
        width: 90%; /* Adjusts button width to 90% of the parent container for better fit on mobile screens */
        margin-left: 17px; /* Centers the button by removing left margin */
        margin-bottom: 20px;
    }
}

/* Media query for even smaller screens (e.g., iPhone 5/SE) */
@media only screen and (max-width: 480px) {
    .ContactForm {
        padding: 10px; /* Reduces padding for very small screens */
        margin: 15px; /* Reduces margin for very small screens */
    }
    .Inputs input, .Inputs textarea {
        height: 40px; /* Slightly reduces input and textarea height for very small screens */
        
    }
}

/* Additional adjustments for very wide screens (e.g., desktop monitors) */
@media only screen and (min-width: 1200px) {
    .ContactForm {
        width: 1100px; /* Sets a fixed width for very wide screens to prevent the form from stretching too much */
        margin: 30px auto; /* Centers the form horizontally */
        height: 78vh;
        margin-bottom: 70px;
    }
    .btn{
        margin-bottom: 30px;
    }
}